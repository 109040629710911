<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Grid</Heading>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Example</Heading>
							<Paragraph>The grid extends a 12-column flexbox grid. With the grid and grid container components, the layout has a wide range of size options. Apply the size="" prop to the grid container.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Grid>
							<GridContainer size="12">
								<div class="grid-example">size="12"</div>
							</GridContainer>
							<GridContainer size="1">
								<div class="grid-example">size="1"</div>
							</GridContainer>
							<GridContainer size="11">
								<div class="grid-example">size="11"</div>
							</GridContainer>
							<GridContainer size="2">
								<div class="grid-example">size="2"</div>
							</GridContainer>
							<GridContainer size="10">
								<div class="grid-example">size="10"</div>
							</GridContainer>
							<GridContainer size="3">
								<div class="grid-example">size="3"</div>
							</GridContainer>
							<GridContainer size="9">
								<div class="grid-example">size="9"</div>
							</GridContainer>
							<GridContainer size="4">
								<div class="grid-example">size="4"</div>
							</GridContainer>
							<GridContainer size="8">
								<div class="grid-example">size="8"</div>
							</GridContainer>
							<GridContainer size="5">
								<div class="grid-example">size="5"</div>
							</GridContainer>
							<GridContainer size="7">
								<div class="grid-example">size="7"</div>
							</GridContainer>
							<GridContainer size="6">
								<div class="grid-example">size="6"</div>
							</GridContainer>
							<GridContainer size="6">
								<div class="grid-example">size="6"</div>
							</GridContainer>
							<GridContainer size="2">
								<div class="grid-example">size="2"</div>
							</GridContainer>
							<GridContainer size="3">
								<div class="grid-example">size="3"</div>
							</GridContainer>
							<GridContainer size="4">
								<div class="grid-example">size="4"</div>
							</GridContainer>
							<GridContainer size="3">
								<div class="grid-example">size="3"</div>
							</GridContainer>
						</Grid>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Even Columns</Heading>
							<Paragraph>Add the attribute col="" to the grid component for even sized grid containers.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Grid col="3">
							<GridContainer>
								<div class="grid-example">col="3"</div>
							</GridContainer>
							<GridContainer>
								<div class="grid-example">col="3"</div>
							</GridContainer>
							<GridContainer>
								<div class="grid-example">col="3"</div>
							</GridContainer>
						</Grid>
						<br/><br/>
						<Grid col="4">
							<GridContainer>
								<div class="grid-example">col="4"</div>
							</GridContainer>
							<GridContainer>
								<div class="grid-example">col="4"</div>
							</GridContainer>
							<GridContainer>
								<div class="grid-example">col="4"</div>
							</GridContainer>
							<GridContainer>
								<div class="grid-example">col="4"</div>
							</GridContainer>
						</Grid>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Grid Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">col</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">2<br/>3<br/>4</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The amount of columns the GridColumns will evenly span.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Grid Container Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">1-12</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the Grid Column.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										left<br/>
										right<br/>
										middle<br/>
										top<br/>
										bottom<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the alignment of the child element.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>
.grid-example{
	height: 40px;
	width: 100%;
	background: #f8f9fa;
	color: #fa5f1c;
	font-family: 'Courier New', monospace;
	font-size: 13px;
	line-height: 40px;
	text-align: center;
}
</style>
